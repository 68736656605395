<script>
import projectsData from '../assets/projects.json'

export default {
  name: 'Projects',
  data () {
    return {
      projects: projectsData
    }
  },
  methods: {
    linksToCsv: function (project) {
      if (!project && !project.meta && !project.meta.links) {
        return null
      }

      const links = []
      for (const key in project.meta.links) {
        const value = project.meta.links[key]
        links.push(`<a href="${value}" target="_blank" rel="noopener noreferrer" title="${value}">${key}</a>`)
      }

      return links.join(' & ')
    }
  }
}
</script>

<template>
  <div
    id="projects"
    class="container"
  >
    <h1>Some Projects</h1>
    <div class="d-flex flex-row flex-wrap justify-content-center">
      <div
        v-for="project in projects"
        :key="project.title"
        class="project"
      >
        <a
          v-if="!project.meta.video"
          :href="project.url"
          :class="{'disabled': !project.url}"
        >
          <img
            :src="project.image"
            :alt="`${project.title} logo`"
            :class="{ 'bg-white border-round': project.title.toLowerCase().includes('natgeo') }"
            loading="lazy"
          >
          <h4>
            {{ project.title }}
          </h4>
        </a>
        <div v-if="project.meta.video">
          <video
            :poster="project.image"
            controls
          >
            <source :src="project.url">
            Your browser does not support the HTML5 video tag.
          </video>
          <a class="disabled">
            <h4>
              {{ project.title }}
            </h4>
          </a>
        </div>

        <!-- <h4>
          <a
            :href="project.url"
            :class="{'disabled': !project.url}"
          >
            {{ project.title }}
          </a>
        </h4> -->
        <p class="text-start">
          <b>Used:</b> {{ project.meta.used }}
        </p>

        <p class="text-start">
          <b>Info:</b> <span v-html="project.meta.info" />
        </p>

        <p
          v-if="project.meta.links"
          class="text-start"
        >
          <b>Links:</b> <span v-html="linksToCsv(project)" />
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="less">
#projects {
  .d-flex {
    flex-flow: row wrap;
    justify-content: space-around;

    & > div.project {
      flex: 1 0 30%;
      margin: 0 1rem;
      max-width: 250px;

      img, video {
        max-width: 300px;
        width: 100%;
        height: auto;
      }
    }

    @media screen and (max-width: 991px) {
      & > div.project {
        flex: 1 0 90%;
        max-width: none;
      }
    }
  }
}
</style>
